

<template>
  <div>
    <!-- <iframe  allow="camera *;microphone *" class="responsive-iframe" v-if="uuidSession" :src="link" /> -->
    <jitsi-meet
      class="responsive-iframe"
      ref="jitsiRef"
      domain="meet.jit.si"
      :options="options"
    />
  </div>
</template>
 
 <script>
import JitsiMeet from "../components/app/sections/JitsiMeet.vue";
export default {
  components: { JitsiMeet },
  data() {
    return {
      config: {
        configOverwrite: {
          disableDeepLinking: true,
          // prejoinPageEnabled: true,
          // disableLocalVideoFlip: true,
          // doNotFlipLocalVideo: true,
          // hideParticipantsStats: true,
          // disableRemoteMute: true,
          // disableRemoteControl: false,
          // hideConferenceTimer: true,
          // remoteVideoMenu: {
          //   disableKick: true,
          //   disableGrantModerator: true,
          // },
          subject: "Psicoplanner",
        },
        interfaceConfigOverwrite: {
          LANG_DETECTION: true,
          DEFAULT_LANGUAGE: "pt-BR",
          // SHOW_JITSI_WATERMARK: false,
          // HIDE_DEEP_LINKING_LOGO: true,
          // DEFAULT_LOGO_URL:
          //   "https://meu.psicoplanner.com.br/logo-original-white.png",
          // DEFAULT_WELCOME_PAGE_LOGO_URL:
          //   "https://meu.psicoplanner.com.br/logo-original-white.png",
          // DEFAULT_BACKGROUND: "#474747",
          // FILM_STRIP_MAX_HEIGHT: "0.1",
          // SHOW_CHROME_EXTENSION_BANNER: false,
          // DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
          // LANG_DETECTION: true,
          // VIDEO_QUALITY_LABEL_DISABLED: true,
          // CONNECTION_INDICATOR_DISABLED: true,
          // TOOLBAR_BUTTONS: [
          //   "microphone",
          //   "camera",
          //   "fullscreen",
          //   "tileview",
          //   "select-background",
          //   "toggle-camera",
          //   "desktop",
          //   "etherpad",
          //   "hangup",
          // ],
        },
      },
    };
  },

  created() {
    this.uuidSession = this.$route.params.uuid;
  },
  computed: {
    options() {
      return {
        roomName: this.uuidSession,
        noSSL: false,
        ...this.config,
      };
    },
    // uuidSession() {
    //   return this.$route.params.uuid;
    // },
    // link() {
    //   return `https://meet.jit.si/psicoplanner/${this.uuidSession}#config.disableDeepLinking=true`;
    //   // 'https://meet.jit.si/JAXYZtest?allow=''microphone; camera'""
    // },
  },
};
</script>

<style   scoped>
a.watermark.leftwatermark {
  display: none;
  visibility: hidden;
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>

